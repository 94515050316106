<template>
  <base-missing-data class="base-missing-data-page" with-controls>
    <template #icon>
      <img :src="urlImg" class="base-missing-data-page__image" />
    </template>
    <template #message>
      <slot name="message" />
    </template>
    <template #buttons>
      <slot v-if="withControls" name="buttons" />
    </template>
  </base-missing-data>
</template>

<script>
// Components
import BaseMissingData from '@/components/BaseMissingData.vue';

export default {
  name: 'BaseMissingDataPage',

  components: { BaseMissingData },

  inject: ['media'],

  props: {
    urlImg: { type: String, required: true },
    withControls: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.base-missing-data-page {
  &__image {
    margin-bottom: 32px;
    @media (max-width: map.get($--screens, 'xs')) {
      width: 164px;
    }
  }
}
</style>
