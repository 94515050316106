<template>
  <div class="base-missing-data d-flex flex-column align-center justify-center">
    <span class="d-flex justify-center">
      <slot name="icon" />
    </span>

    <span class="base-missing-data__header d-flex text-center font-weight-regular text-h5 text-sm-h4">
      <slot name="message" />
    </span>

    <div
      class="base-missing-data__buttons d-flex justify-center mt-10"
      :class="{ 'flex-column align-center': media.isMobile }"
    >
      <slot v-if="withControls" name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMissingData',

  inject: ['media'],

  props: {
    withControls: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.base-missing-data {
  @media (max-width: map.get($--screens, 'sm')) {
    min-height: 50vh;
  }

  &__header {
    color: rgba($--black-color-0, 0.5);
    height: fit-content;
  }

  &__buttons {
    width: 100%;
  }
}
</style>
