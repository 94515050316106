<template>
  <div class="dashboard-portfolio">
    <h2 class="dashboard-portfolio__title">
      {{ $t('dashboard.portfolio') }}
    </h2>
    <v-row dense>
      <v-col v-for="card in portfolioCards" :key="card.id" cols="6" sm="3">
        <base-card class="dashboard-portfolio__card" :loading="loading">
          <template v-slot:header>
            <h6 class="dashboard-portfolio__card-title">
              {{ $t(card.title) }}
            </h6>
          </template>
          <p class="dashboard-portfolio__card-text">
            {{ formatValue(card.value) }}
            <span v-if="card.title === 'dashboard.totalArea' && !loading">m<sup>2</sup></span>
          </p>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseCard from '@/components/BaseCard.vue';
import dashboardService from '@/services/dashboard';
import { separateThouthands } from '@/utils/priceFormatting';

const portfolioConfig = [
  { id: 2, title: 'dashboard.projects', name: 'projects' },
  { id: 1, title: 'dashboard.buildings', name: 'buildings' },
  { id: 4, title: 'dashboard.units', name: 'units' },
  { id: 3, title: 'dashboard.totalArea', name: 'totalArea' },
];

export default {
  name: 'DashboardPortfolio',
  components: { BaseCard },
  data() {
    return {
      cards: [],
      loading: false,
    };
  },
  computed: {
    portfolioCards() {
      return portfolioConfig.map(({ name, ...config }) => {
        return { ...config, value: this.cards[name] };
      });
    },
  },
  mounted() {
    this.loading = true;
    dashboardService
      .getCompanySummaryInfo()
      .then(res => {
        this.cards = res;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    formatValue(value) {
      return value ? separateThouthands(value) : '';
    },
  },
};
</script>

<style lang="scss">
.dashboard-portfolio {
  &__title {
    font-weight: $--font-weight-bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
  &__card {
    height: 100%;
    min-height: 104px;
    @media (max-width: map.get($--screens, 'sm')) {
      min-height: 76px;
    }
  }
  &__card-title {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__card-text {
    font-weight: $--font-weight-medium;
    font-size: 34px;
    line-height: 44px;
    font-weight: 500;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 24px;
      line-height: 34px;
    }
    @media (max-width: map.get($--screens, 'xs')) {
      font-size: 18px;
      line-height: 26px;
    }

    sup {
      top: 0;
      vertical-align: super;
      font-size: 18px;
      font-weight: 600;

      @media (max-width: map.get($--screens, 'sm')) {
        font-size: 14px;
      }
      @media (max-width: map.get($--screens, 'xs')) {
        font-size: 10px;
      }
    }
  }
}
</style>
