<template>
  <base-missing-data-page class="dashboard-missing-data" :url-img="$options.imageDataMissing" with-controls>
    <template v-if="isPaymentsMode" #message>
      {{ $t('dashboard.missing_message') }}
    </template>
    <template v-else-if="isTasksMode" #message>
      {{ $t('dashboard.missing_message_tasks') }}
    </template>
    <template #buttons>
      <v-btn v-if="isPaymentsMode" :to="invoiceRoute" color="primary" large elevation="0">
        {{ $t('button.open_invoice') }}
        <v-icon right>mdi-menu-right</v-icon>
      </v-btn>
      <v-btn v-if="isTasksMode" :to="taskRoute" color="primary" large elevation="0">
        {{ $t('button.open_task') }}
        <v-icon right>mdi-menu-right</v-icon>
      </v-btn>
    </template>
  </base-missing-data-page>
</template>

<script>
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-employees.svg';
import { INVOICES, TASKS } from '@/constants/routes';
import { PAYMENTS, TASKS as TASKS_MODE } from '@/constants/dashboardModes';

export default {
  name: 'DashboardMissingData',

  components: { BaseMissingDataPage },

  props: {
    mode: { type: String, default: TASKS },
  },

  computed: {
    invoiceRoute() {
      return { name: INVOICES };
    },
    taskRoute() {
      return { name: TASKS };
    },
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS_MODE;
    },
  },

  imageDataMissing,
};
</script>
